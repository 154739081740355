html,
body {
  --color-error: #ff0000;
  --color-highlight: #86d8ff;
  --color-main: #0e76bc;
  --bottom-panel-height: 30%;
  --bottom-panel-font-family: var(--menu-font-family);
  --bottom-panel-font-size: 1vw;
  --bottom-panel-line-height: 20.8px;
  --glow-color: #86d8ff;
  --height-minimized-panel: 40%;
  --menu-font-size: var(--bottom-panel-font-size);
  --menu-font-family: Trebuchet, Helvetica, Arial, sans-serif;
  --table-hover-color: rgb(224, 224, 224);
  --text-color: #ffffff;
  --width-menu: 30%;
  --width-side-panel: 40%;

  /* width: 100%;
  height: 100%; */
  /* position: absolute; */
}

.showGuideDescriptionsButton:focus {
  border-width: 3px;
}