.play-button {
    top: 15px;
    width: 40px;
    height: 40px;
    left: 250px;
    position: fixed;
    transition: all 0.5s;
    cursor: pointer;
    opacity: 1;
    background-color: white;
    color: black;
    border: 2px solid #4CAF50;
    border-radius: 20%;
}

.play-button:hover {
    background-color: #4CAF50;
    color: white;
}

.download-button {
    top: 105px;
    width: 40px;
    height: 40px;
    left: 250px;
    position: fixed;
    transition: all 0.5s;
    cursor: pointer;
    opacity: 1;
    background-color: white;
    color: black;
    border: 2px solid #4CAF50;
    border-radius: 20%;
}

.download-button:hover {
    background-color: #4CAF50;
    color: white;
}

.stop-button {
    top: 60px;
    width: 40px;
    height: 40px;
    left: 250px;
    position: fixed;
    transition: all 0.5s;
    cursor: pointer;
    opacity: 1;
    background-color: white;
    color: black;
    border: 2px solid #f44336;
    border-radius: 20%;
}

.stop-button:hover {
    background-color: #f44336;
    color: white;
}

.exit-button {
    top: 150px;
    width: 40px;
    height: 40px;
    left: 250px;
    position: fixed;
    transition: all 0.5s;
    cursor: pointer;
    opacity: 1;
    background-color: white;
    color: black;
    border: 2px solid #f44336;
    border-radius: 20%;
}

.exit-button:hover {
    background-color: #f44336;
    color: white;
}

.alert-hidden {
    position: absolute;
    background-color: rgb(197, 197, 139);
}

.container-pdf {
    width: 100%;
    padding-left: 350px;
    background-color: #57585a;
}

.page {
    padding-left: 5%;
}

.app-header {
    background-color: #57585a;
    display: flex;
    align-items: center;
    justify-content: center;
    zoom: 221%;
}

span.k-icon {
    font-size: 25px;
    color: rgba(31, 30, 30, 0.863);
}

span.k-icon.k-i-download {
    font-size: 35px;
}

.button-divs {
    display: inline;
}