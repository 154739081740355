.app-logo {
  width: 100%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.app-footer {
  width: 100%;
  height: 50px;
  padding-top: 20px;
  text-align: center;
  color: #0051ba;
  font-size: 1rem;
}

a.dropdown-toggle.nav-link::after {
  width: .7em;
}