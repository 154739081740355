.component-container {
  padding-top: 1px;
  padding-left: 1px;
  margin-bottom: 1px;
  background-color: white;
}

.info-header {
  text-decoration: underline;
  font-size: medium;
}

.body-text {
  font-size: 14px;
}

.perf-level-cont {
  display: table;
  float: left;
  height: auto;
  width: 24%;
  padding: 10px 10px 10px 10px;
  margin: 2px;
  background-color: #e6e6e6;
  text-align: left;
}

.report-body {
  margin-top: 20px;
  width: 1000px;
}

.performancelevels .card {
  border-top-width: 8px;
  border-top-style: solid;
  border-top-color: rgba(255, 165, 0, 1);
}

.performancelevels:nth-child(1) .card {
  border-top-color: rgba(255, 165, 0, 1);
}

.performancelevels:nth-child(2) .card {
  border-top-color: rgba(255, 165, 0, 0.75);
}

.performancelevels:nth-child(3) .card {
  border-top-color: rgba(255, 165, 0, 0.5);
}

.performancelevels:nth-child(4) .card {
  border-top-color: rgba(255, 165, 0, 0.25);
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

.k-grid-header {
  font-weight: bold;
  font-size: 15px;
}

span.k-icon.k-i-file-pdf {
  font-size: 25px;
  color: red;
}

.pdf-button {
  border: none;
  background: none;
}

.showGuideDescriptionsButton {
  margin: 28px;
  background: #0e76bc;
  color: white;
  outline: none
}

.showGuideDescriptionsButton {
  background: rgb(13, 103, 155);
  color: #fafdff;
}

div.k-popup.k-child-animation-container.k-slide-down-enter.k-slide-down-enter-active {
  margin: 0.5px;
  padding: 10px;
  color: #787878;
  background-color: #f1f4fa;
  border: 1px solid rgba(0, 0, 0, .05);
}