.login {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f5f5f5;
  min-height: 100vh;
  background-image: url("./images/login_bg.jpg");
  background-size: cover;
  background-position: center;
}
.login__form {
  width: 100%;
  max-width: 350px;
  padding: 15px;
  margin: auto;
  background-color: #fff;
}
.login__kitelogo img {
  max-width: 100%;
  height: 100px;
}
.login__submit {
  margin-top: 1rem !important;
  width: 100% !important;
}
.request {
  margin-top: .5rem !important;
  width: 100% !important;
}

.accesscode_div {
  padding-top: 20px;
}

.buttonLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #007bff;
  cursor: pointer;
}

.buttonLink:focus {
  outline: none;
  color: #7714b1 !important;
}

.link_color {
  color: #0e76bc;
  text-decoration: none;
}
