/* style used for highlighting the pdf*/

#full-pdf {
    top: 26px;
    width: 940.3px;
    height: 2545px;
    left: 379px;
    border: 2px solid rgb(37, 218, 13);
    position: absolute;
}

#marks-level {
    position: absolute;
    top: 540px;
    width: 458px;
    height: 125px;
    left: 379px;
    margin: 10px;
    opacity: 1;
    border: 3px solid red;
    border-radius: 16%;
}

#performance-level-description {
    position: absolute;
    top: 1015px;
    width: 885px;
    height: 173px;
    left: 384px;
    margin: 10px;
    opacity: 1;
    border: 3px solid #15ff00;
}

#symbol-description {
    position: absolute;
    top: 1391px;
    width: 481px;
    height: 35px;
    left: 795px;
    margin: 10px;
    opacity: 1;
    border: 3px solid #15ff00;
}

#level-score {
    position: absolute;
    top: 245px;
    width: 869px;
    height: 118px;
    left: 401px;
    margin: 10px;
    opacity: 1;
    border: 3px solid #15ff00;
    border-radius: unset;
}

#act-scoring {
    position: absolute;
    top: 2266px;
    width: 805px;
    height: 145px;
    left: 388px;
    margin: 10px;
    opacity: 1;
    border: 3px solid #15ff00;
}

#measure {
    position: absolute;
    top: 2410px;
    width: 810px;
    height: 108px;
    left: 384px;
    margin: 10px;
    opacity: 1;
    border: 3px solid #15ff00;
}

#score-description {
    position: absolute;
    top: 385px;
    width: 449px;
    height: 290px;
    left: 845px;
    margin: 10px;
    opacity: 1;
    border: 3px solid #15ff00;
}

#student-performance-description {
    position: absolute;
    top: 1392px;
    width: 906px;
    height: 565px;
    left: 386px;
    margin: 10px;
    opacity: 1;
    border: 3px solid #15ff00;
}

#addtional-description {
    position: absolute;
    top: 2212px;
    width: 510px;
    height: 60px;
    left: 384px;
    margin: 10px;
    opacity: 1;
    border: 3px solid #15ff00;
}

#addtional-description-2 {
    position: absolute;
    top: 2440px;
    width: 861px;
    height: 68px;
    left: 390px;
    margin: 10px;
    opacity: 1;
    border: 3px solid #15ff00;
}

#addtional-description-noact {
    position: absolute;
    top: 2340px;
    width: 510px;
    height: 77px;
    left: 384px;
    margin: 10px;
    opacity: 1;
    border: 3px solid #15ff00;
}

#score {
    position: absolute;
    top: 390px;
    width: 270px;
    height: 151px;
    left: 469px;
    margin: 10px;
    opacity: 1;
    border: 3px solid #15ff00;
}